<style scoped lang="scss">
@import './SectionStyle.scss';
</style>

<template>
  <section
    class="section section-hero"
    :class="[newBanner ? 'section-hero-new' : '']"
  >
      <div class="background el-cover top-banner-home">
        <template v-if='$device.isDesktopOrTablet && focusSearchBox'>
          <h1 :class="['absolute z-3', h1DesktopClass]" style="text-shadow: 0 0 4px rgba(0,0,0,.4);" v-html="titleHero"></h1>
          <div class='fixed wp-100 hp-100 top-0 left-0 z-2' style='background-color: rgba(0,0,0,0.25)'></div>
        </template>
        <ClientOnly>
          <Carousel v-if="carousel.length" class="hp-100" :wrap-around="true" :modelValue='slideIndex' :currentSlideIndex='slideIndex'>
            <template #counter />
            <template v-if='$device.isDesktopOrTablet'>
              <Slide v-for='(item, index) in carousel' :key='index'>
                <a :class="['db wp-100 hp-100',focusSearchBox ? 'pointer-none' : '']" :href="mapQueryParams(item.placeholder)" target="_blank">
                  <h1 :class="h1DesktopClass" v-if='!focusSearchBox' style="text-shadow: 0 0 4px rgba(0,0,0,.4);" v-html="titleHero"></h1>
                  <BaseResponsiveImages :src="item.image" :alt="item.alt_text" />
                </a>
              </Slide>
            </template>
            <template v-else>
              <Slide class='relative' v-for='(item, index) in carousel' :key='index'>
                <a class="db wp-100 hp-100" :href="mapQueryParams(item.placeholder)" target="_blank">
                  <h1 :class="h1MobileClass" style="text-shadow: 0 0 4px rgba(0,0,0,.4);" v-html="titleHero"></h1>
                  <BaseResponsiveImages :src="item.image" :alt="item.alt_text" />
                </a>
              </Slide>
            </template>
          </Carousel>
          <template v-if="carouselFirstItem" #fallback>
            <!-- Show first item carousel ssr -->
            <template v-if='$device.isDesktopOrTablet'>
                <a :class="['db wp-100 hp-100',focusSearchBox ? 'pointer-none' : '']" :href="mapQueryParams(carouselFirstItem.placeholder)" target="_blank">
                  <h1 :class="h1DesktopClass" v-if='!focusSearchBox' style="text-shadow: 0 0 4px rgba(0,0,0,.4);" v-html="titleHero"></h1>
                  <BaseResponsiveImages :src="carouselFirstItem.image" :alt="carouselFirstItem.alt_text" />
                </a>
            </template>
            <template v-else>
                <a class="db wp-100 hp-100" :href="mapQueryParams(carouselFirstItem.placeholder)" target="_blank">
                  <h1 :class="h1MobileClass" style="text-shadow: 0 0 4px rgba(0,0,0,.4);" v-html="titleHero"></h1>
                  <BaseResponsiveImages :src="carouselFirstItem.image" :alt="carouselFirstItem.alt_text" />
                </a>
            </template>
          </template>
        </ClientOnly>
      </div>

    <div class="container">
      <div class="flex-wrapper" :class="[newBanner ? 'new-wrapper' : '']">
        <div class="wrapper justify-center">
          <template v-if="!isAgent">
            <h1 class="wrapper-title" v-if="!newBanner">
              Customise your own<br />
              private holiday using<br />
              our Local Designers
            </h1>
          </template>
          <h1 class="wrapper-title" v-else>
            Customise your client's <br />
            private holiday using <br />
            our Local Designers
          </h1>
          </div>
          <ClientOnly>
            <div class="wp-100">
              <NewSearchBox ref="newSearchBox" @trigger='searchBoxStatus' />
            </div>
          </ClientOnly>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { PHONE } from '~/constant/common'
import Carousel from '~/components/ui/carousel/CarouselHomeBanner'
import Slide from '~/components/ui/carousel/Slide'
import BaseResponsiveImages from '~/components/ui/BaseResponsiveImages/BaseResponsiveImages'
import { assetCDN } from '~/helper/index.js'
import NewSearchBox from '../NewSearchBox/NewSearchBox.vue'

export default {
  components: {
    Carousel,
    Slide,
    NewSearchBox,
    BaseResponsiveImages,
  },
  data() {
    return {
      window,
      PHONE
    }
  },
  props: {
    isAgent: {
      type: Boolean,
      default: false,
    },
    newBanner: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const titleHero = ref(`Designer travel is independent,<br v-if="windowWidth > PHONE" /> curated and extraordinary`)
    const store = useStore()
    const newSearchBox = ref(null)
    const h1DesktopClass = 'absolute left-p-50 top-p-40 trans-50 wrapper-title z-2 fw-700 tc wp-100 tc'
    const h1MobileClass = 'absolute left-p-50 top-p-35 trans-50 wrapper-title z-2 fw-700 wp-100 tc'
    const focusSearchBox = ref(false)
    const ASSETS_CDN = assetCDN()
    const carousel = computed(() => store.state.generalStore.carousel.listItems)
    const carouselFirstItem = computed(() => {
      if(carousel.value.length === 0) return null
      return carousel.value[0]
    })

    const slideIndex = computed(() => store.state.searchStore.placeholderTextIndex)

    const mapQueryParams = (string)=> {
      return `/search-result?q=${string.replace(/ /g,'+')}`
    }
    const searchBoxStatus = (status) => {
      focusSearchBox.value = status
    }

    return {
      titleHero,
      newSearchBox,
      slideIndex,
      h1DesktopClass,
      h1MobileClass,
      searchBoxStatus,
      focusSearchBox,
      carousel,
      ASSETS_CDN,
      carouselFirstItem,
      mapQueryParams
    }
  },
}
</script>
<style lang="scss" scoped>
.wrapper-title {
    color: white;
    font-size: 48px;
    line-height: 52px;
    @include phone {
      font-size: 32px;
      line-height: 40px;
      width: 100%;
    }
}
.top-banner-home .carousel__slide {
  transition: opacity 0.8s ease-in; position: absolute; height: 100%;
}
</style>

<script setup>
</script>
