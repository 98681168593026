<template>
  <section class="section section-steps bg-gray-300">
    <div class="container pt-40 pb-60">
      <div class="grid justify-between items-end grid-template-columns-2 mb-40">
        <h2 class="how-it-work w-fit gray-dark fw-800 fs-12 lh-15 ttu">
          How it works
        </h2>
        <router-link
          class="w-fit justify-self-end fs-14 lh-20"
          :to="{ path: `/how-it-works` }"
          >See how it works</router-link
        >
      </div>
      <div
        class="grid grid-gap-40"
        :class="[$device.isDesktopOrTablet ? 'grid-template-columns-3' : '']"
      >
        <div class="enquire flex flex-row grid-gap-20 items-center mb-items-start">
          <img
            :src="`${ASSETS_CDN}/images/home/icons/step-enquire.svg`" width='60' height='60' alt='Explore icon'
          />
          <div class="content">
            <h1 class="fw-800 fs-20 lh-24 mb-8 tl-mb-6">1. Explore</h1>
            <p class="fs-14 lh-20 mb-0">
              Get inspired by our gallery of 2000+ extraordinary travel
              journeys.
            </p>
          </div>
        </div>
        <div class="build flex flex-row grid-gap-20 items-center mb-items-start">
          <img
            :src="`${ASSETS_CDN}/images/home/icons/step-build.svg`" width='60' height='60' alt='Design icon'
          />
          <div class="content">
            <h1 class="fw-800 fs-20 lh-24 mb-8 tl-mb-6">2. Design</h1>
            <p class="fs-14 lh-20 mb-0">
              Work with an expert Local Designer to design your extraordinary
              trip.
            </p>
          </div>
        </div>
        <div class="book flex flex-row grid-gap-20 items-center mb-items-start">
          <img
            :src="`${ASSETS_CDN}/images/home/icons/step-book.svg`" width='60' height='60' alt='Book icon'
          />
          <div class="content">
            <h1 class="fw-800 fs-20 lh-24 mb-8 tl-mb-6">3. Book</h1>
            <p class="fs-14 lh-20 mb-0">
              Book with confidence through the secure Designer Journeys
              platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
// import { computed } from 'vue'
// import { useStore } from 'vuex'
// import { PHONE } from '~/constant/common'
import {assetCDN} from '~/helper/index.js';

// const store = useStore()
const ASSETS_CDN = assetCDN()

// const windowWidth = computed(
//   () => store.state.generalStore.generalData.windowWidth,
// )
</script>
<style lang="scss" scoped>
.section-steps {
  .container {
    padding: 40px 80px 60px 80px;

    @include tablet {
      padding: 40px 60px 60px;
    }

    @include phone {
      padding: 40px 20px 60px;
    }
  }

  a:link,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: $gray-dark;
    text-decoration: underline !important;
  }

  .how-it-work {
    letter-spacing: 1px;
  }
}
</style>
