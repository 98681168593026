<style scoped lang="scss">
@import './HomeStyle.scss';
</style>

<template>
  <div>
    <NuxtLayout>
      <template #body_page>
        <div id="page-home">
          <SectionHeroNew
              newBanner
          />
          <SectionSteps />
          <SectionTrending />
          <SectionTrips />
          <SectionBenefits />
        </div>
      </template>
    </NuxtLayout>
    <ClientOnly>
    <Teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
        {
        "@type": "ListItem",
        "position": 1,
        "item": {
        "@id": "https://www.designerjourneys.com",
        "name": "Home"
        }
        }
        ]
        }
      </component>
    </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup>
import { debounceAmplitudeScriptTracking } from '~/mixins/amplitude'
import SectionHeroNew from '~/components/sections/SectionGlobalHome/SectionHero/SectionHeroNew'
import SectionSteps from '~/components/sections/SectionGlobalHome/SectionSteps/SectionSteps'
import SectionTrending from '~/components/sections/SectionGlobalHome/SectionTrending/SectionTrending'
import SectionTrips from '~/components/sections/SectionGlobalHome/SectionTrips/SectionTrips'
import SectionBenefits from '~/components/sections/SectionGlobalHome/SectionBenefits/SectionBenefits'
import { useStore } from 'vuex'
import { onMounted } from 'vue'

const store = useStore()
onMounted(() => {
  debounceAmplitudeScriptTracking("View Home")
})

useHead({
  title:  'Experience Travel in Extraordinary Ways | Designer Journeys',
  meta:
    [
      {
        name: `title`,
        content: 'Experience Travel in Extraordinary Ways | Designer Journeys',
      },
      {
        name: `description`,
        content: 'Explore our gallery of Designer Journeys from our experienced Local Travel Designers.'
      },
    ],
  link: [
    // { rel: 'canonical', href: window.location.href.split("#")[0].split("?")[0]},
  ],
  // script: [
  //   { type: 'text/javascript', src: 'https://www.googleoptimize.com/optimize.js?id=GTM-5NRNSCQ' }
  // ]
})

await store.dispatch('searchStore/setSuggestionsSearch')
await store.dispatch('generalStore/fetchCarouselData')
</script>
