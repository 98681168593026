<style scoped lang="scss">
@import './SectionStyle.scss';
</style>

<template>
  <section class="section section-trending">
    <div class="container">
      <div class="headline">
        <h2 class="headline-title fw-900">Popular Destinations</h2>

        <div class="headline-description">
          <p class='mb-0'>
            Explore our most popular destinations to help decide where your next extraordinary trip will be.
          </p>
        </div>
      </div>
      <div class="inner-wrapper">
        <div class="flex-wrapper">
          <div
            class="item"
            v-for="(item, index) in trendingList"
            :key="`home-trending-item-${index}`"
            style='-webkit-backface-visibility: hidden;-webkit-transform: translate3d(0, 0, 0);'
          >
            <h3>
            <router-link
              :to="`${item.url}`"
              class="item-link el-cover"
              :title="`View destination: ${item.name}`"
               :aria-label="`View destination: ${item.name}`"
              @click="setGallerySource"
              >
            </router-link>
<!--            <a
              :href="`/${item.url}`"
              class="item-link el-cover"
              :title="`View destination: ${item.name}`"
               :aria-label="`View destination: ${item.name}`"
              @click="setGallerySource"
              >
            </a>-->
            </h3>
            <ClientOnly fallbackTag="div">
              <LazyLoad class="item-image el-cover" :data-distance="isMobile ? 0.05 : 0.2" when-visible>
                <ResponsiveImages v-if="item.image.size" :src='item'></ResponsiveImages>
                <img v-else class="img-cover" :alt="item.name" :src="item.image.url">
              </LazyLoad>
              <template #fallback>
                <ResponsiveImages v-if="item.image.size" :src='item'></ResponsiveImages>
                <img v-else class="img-cover" :alt="item.name" :src="item.image.url">
              </template>
            </ClientOnly>
            <div class="item-image el-cover">
              <ResponsiveImages v-if="item.image.size" :src='item'></ResponsiveImages>
              <img v-else class="img-cover" :alt="item.name" :src="item.image.url">
            </div>
            <div class="item-title fw-900">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

  <script>
  import { onMounted, /*onUnmounted,*/ ref } from 'vue'
  import { /*createStore,*/ useStore } from 'vuex'
  // import trendingDesStore from '~/store/modules/Home/trending-destinations'
  import trendingDesApi from '~/composables/api/homeApi'
  import LazyLoad from '~/components/ui/lazy-load/LazyLoad'
  import ResponsiveImages from '~/components/elements/DestinationCard/ResponsiveImages'

  export default {
    components: { ResponsiveImages, LazyLoad },
    async setup() {
      const { $logger } = useNuxtApp();
      // const store = createStore({})
      const existedStore = useStore()
      const {isMobile} = useDevice()

      const trendingList = ref([])

      // if (!(store && store.state && store.state.trendingDesStore))
      //   store.registerModule('trendingDesStore', trendingDesStore)

      const getTrendingDesList = async () => {
        try {
          const {data: resData} = (await trendingDesApi.getTrendingDes()).data.value
          // const resData = (await trendingDesApi.getTrendingDes())
          // console.log('[getTrendingDesList]',resData?.data?.value?.data)
          // console.log('[getTrendingDesList error]',resData?.error?.value?.data)
          // resData?.error?.value?.data: { success: false, data: null, message: 'Undefined variable $i', errors: null }
          // trendingList.value = resData?.data?.value?.data || resData?.error?.value?.data
          trendingList.value = resData
          // store.commit('trendingDesStore/setTrendingDesData', resData)
        } catch (e) {
          $logger.log(e)
        }
      }

      const setGallerySource = async () => {
        await existedStore.commit('heapStore/setGallerySource', "homepage-trending-section")
      }

      // onMounted(async () => {
      //   await getTrendingDesList()
      // })
      /*onUnmounted(() => {
        store.unregisterModule('trendingDesStore')
      })*/
      await getTrendingDesList()
      return {
        getTrendingDesList,
        trendingList,
        isMobile,
        setGallerySource
      }
    }
  }
  </script>
